import "bootstrap";

import $ from "jquery";
import AOS from "aos";

AOS.init();

import "lightbox2";
grecaptcha.ready(function() {
  grecaptcha.execute('6LcJTk4qAAAAAItEgVOXjsc0fiFPqOA6hV3zN0JC', {action: 'submit'}).then(function(token) {
    // Dodaj token do formularza
    document.getElementById('recaptchaToken').value = token;
  });
});

const url = "./send-script.php";
const form = document.querySelector("#contactForm");
const fields = form.querySelectorAll("[required]");
const message = form.querySelector(".form-message");
const invalidFieldClassName = "is-invalid";

form.setAttribute("novalidate", true);

function clearForm() {
  const honeyInput = document.querySelector('input[name="honey"]');
  fields.forEach((field) => {
    // Check if the field is an input or textarea, then clear its value
    if (field.tagName === "INPUT" || field.tagName === "TEXTAREA") {
      field.value = "";
    }
    // Additionally, if there are other types of fields like 'SELECT', you can reset them to their default state
    else if (field.tagName === "SELECT") {
      field.selectedIndex = 0; // Reset to the first option
    }
    // Add more conditions here if you have other types of fields
  });
  // Check if the element exists to avoid errors
if (honeyInput) {
  // Set the value of the input to an empty string
  honeyInput.value = '';
} else {
  // Log or handle the case where the input does not exist
  console.log('Input with name "honey" not found.');
}
}

for (const field of fields) {
  field.addEventListener("input", () =>
    field.classList.toggle(invalidFieldClassName, !field.checkValidity())
  );
}

async function makeRequest(data) {
  const res = await fetch(url, {
    method: "post",
    body: data,
  });
  if (res.ok) {
    return res.json();
  }
  return Promise.reject(`${res.status}: ${res.statusText}`);
}

function showSubmitSuccess() {
  let ele = document.getElementById("message-status");

  ele.innerHTML =
    " <strong>Wiadomość została wysłana</strong> <br> <span>Dziękujemy za kontakt. Postaramy się odpowiedzieć jak najszybciej</span>";
  $("#message-status").fadeIn("slow");
  setTimeout(() => {
    $("#message-status").fadeOut("slow");
  }, "2000");

  //form.remove();
}

function showSubmitError() {
  message.innerHTML = "Wysłanie wiadomości się nie powiodło";
}

function afterSubmit(res) {
  if (res.errors) {
    const selectors = res.errors.map((el) => `[name="${el}"]`);
    const fieldsWithErrors = form.querySelectorAll(selectors.join(","));
    for (const field of fieldsWithErrors) {
      field.classList.add(invalidFieldClassName);
    }
  } else {
    if (res.status === "success") {
      showSubmitSuccess();
      clearForm();
    }
    if (res.status === "error") {
      showSubmitError();
    }
  }
}

function checkElements() {
  let formErrors = false;

  for (const field of fields) {
    field.classList.remove(invalidFieldClassName);

    if (!field.checkValidity()) {
      field.classList.add(invalidFieldClassName);
      formErrors = true;
    }
  }

  return formErrors;
}

async function submit(e) {
  e.preventDefault();

  let formErrors = checkElements();

  if (!formErrors) {
    const formData = new FormData(form);

    const submit = form.querySelector("[type=submit]");
    submit.disabled = true;
    submit.classList.add("loading");

    try {
      const response = await makeRequest(formData);
      afterSubmit(response);
    } catch (err) {
      showSubmitError();
    }

    submit.disabled = false;
    submit.classList.remove("loading");
  }
}

form.addEventListener("submit", submit);
